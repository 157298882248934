import React, { FC, useState } from "react";
import { Button } from "shared";
import { send } from "utils/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface FormData {
  name: string;
  email: string;
  phone: string;
}

export const Form: FC = () => {
  const [processing, setProcessing] = useState(false);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const extractedData: FormData = {
      name: formData.get("name") as string,
      email: formData.get("email") as string,
      phone: formData.get("phone") as string,
    };

    setProcessing(true);

    try {
      console.log(extractedData);
      await send(extractedData);
      toast.success("Thank you! We will reply within 48 hours. 🐜");
      form.reset();
      setProcessing(false);
    } catch {
      toast.error("Oh no! Something went wrong, please try again.");
      setProcessing(false);
    }
  };

  return (
    <section id="book" className="home-book5">
      <ToastContainer
        autoClose={5000}
        position="top-center"
        pauseOnFocusLoss={false}
      />
      <form onSubmit={handleSubmit}>
        <div className="home-heading17">
          <h2 className="home-text69">Contact Us!</h2>
        </div>
        <div className="home-form">
          <div className="home-inputs">
            <input
              type="text"
              name="name"
              placeholder="Name"
              autoComplete="name"
              className="input book-input"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="email"
              className="input book-input"
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              autoComplete="tel"
              className="input book-input"
              required
            />
            <div className="home-lower">
              <Button processing={processing} text="Send" type="submit" />
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};
