import React from "react";
import { Helmet } from "react-helmet";
import {
  Reviews,
  WhyZeroBugs,
  HomeHero,
  Services,
  Contact,
  MeetLicensees,
  Footer,
} from "sections";
import "./home.css";

export const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Pest Control | Zero Bugs</title>
        <meta property="og:title" content="Pest Control | Zero Bugs" />
      </Helmet>
      <HomeHero />
      <Reviews />
      <WhyZeroBugs />
      <Services />
      <Contact />
      <MeetLicensees />
      <Footer />
    </div>
  );
};
