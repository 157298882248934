import React, { FC } from 'react';
import { ContactHeader } from './contact-header';
import { Form } from './form';

export const Contact: FC = () => {
	return (
		<>
			{/* <ContactHeader /> */}
			<Form />
		</>
	);
};
