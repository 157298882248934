import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

import "./style.css";
import "./vars.css";
import "./sections.css";

import { Home, Nav, NotFound, Studies } from "./views";
import { logVisit } from "utils/services";
import { checkForVisit } from "utils/fns";

const HR = () => {
  const location = useLocation();

  return location.pathname !== "/" ? <hr /> : null;
};

const Referral = () => {
  const code = new URLSearchParams(useLocation().search).get("ref");
  const visited = localStorage.getItem("code") === code;

  if (!visited && code) {
    checkForVisit(code);
  }

  return null;
};

const App = () => {
  return (
    <Router>
      <Referral />
      <Nav />
      <HR />
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Studies} path="/studies" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
