import { FormData } from "sections/contact/form";

const base = "/.netlify/functions";

export const send = (params: FormData) => {
  console.log(params);
  return fetch(`${base}/email`, {
    method: "POST",
    body: JSON.stringify(params),
  });
};

export const logVisit = (params: { code: string }) => {
  return fetch(`${base}/referral`, {
    method: "POST",
    body: JSON.stringify(params),
  });
};
